import styled from 'styled-components';
import { MainBlock } from '../../styledComponents';
import { breakpoints } from '../../constants/breakpoints';

export const Title = styled.h1`
  @media ${breakpoints.mobile} {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    color: ${(props) => props.color || '#ffffff'};
    text-shadow: ${(props) => (props.color ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.288325)')};
  }

  @media ${breakpoints.tablet} {
    font-size: 32px;
    line-height: 40px;
    width: 500px;
  }

  @media ${breakpoints.laptop} {
    font-size: 48px;
    line-height: 60px;
    width: 900px;
  }
`;

export const Text = styled.span`
  @media ${breakpoints.mobile} {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    color: ${(props) => props.color || '#f5f5f5'};
    text-shadow: ${(props) => (props.color ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.288325)')};
    margin-top: 20px;
  }

  @media ${breakpoints.tablet} {
    font-size: 20px;
    line-height: 25px;
    width: 500px;
  }

  @media ${breakpoints.laptop} {
    font-size: 24px;
    line-height: 30px;
    width: 880px;
  }
`;

export const MainBlockWrapper1 = styled(MainBlock)`
  @media ${breakpoints.mobile} {
    & {
      position: relative;
      box-sizing: border-box;
      padding: 110px 16px 60px;
      text-align: left;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      mix-blend-mode: normal;
    }

    .btn {
      margin-top: 25px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: 110px 24px 60px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding: 100px 0 100px 75px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: 145px 0 150px 75px;
    }

    ${Text} {
      width: 600px;
    }
  }
`;

export const MainBlockWrapper2 = styled(MainBlock)`
  @media ${breakpoints.mobile} {
    & {
      position: relative;
      box-sizing: border-box;
      padding: 400px 16px 60px;
      text-align: left;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      mix-blend-mode: normal;
    }

    .btn {
      margin-top: 25px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: 500px 0 150px 75px;
    }

    ${Text} {
      width: 600px;
    }
  }
`;

export const MainBlockWrapper3 = styled(MainBlock)`
  @media ${breakpoints.mobile} {
    & {
      position: relative;
      box-sizing: border-box;
      padding: 110px 15px 60px;
      text-align: left;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      mix-blend-mode: normal;
    }

    .link {
      width: 100%;
      max-width: 330px;
    }

    .btn {
      width: 100%;
    }

    ${Text} {
      margin-bottom: 38px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: 110px 24px 60px;
    }

    .link {
      width: 100%;
      max-width: none;
    }

    .btn {
      width: auto;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding: 100px 0 100px 75px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: 135px 0 150px 75px;
      height: 830px;
    }

    .btn {
      margin-top: 0;
    }

    ${Title} {
      width: 800px;
    }

    ${Text} {
      width: 600px;
      margin-top: 40px;
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
    }
  }
`;
