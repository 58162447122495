import styled from 'styled-components';
import { breakpoints } from '../../constants/breakpoints';

export const PictureAndTextWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1440px;
      padding: 0 16px 55px;
      box-sizing: border-box;
      text-align: center;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 24px 55px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding: 0 36px 90px;
    }

    .title {
      margin-bottom: 0;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: 0 50px 120px;
    }
  }
`;

export const Container = styled.div`
  @media ${breakpoints.laptop} {
    display: flex;
    margin-top: 80px;
  }
`;

export const Image = styled.div`
  @media ${breakpoints.mobile} {
    background: ${(props) => `url(${props.image}) no-repeat`};
    width: 100%;
    height: 270px;
    background-size: contain;
    background-position: center;
    margin: 50px 0 40px;
  }

  @media ${breakpoints.laptop} {
    width: 50%;
    height: 460px;
    margin: 0;
    background-position: top right;
  }
`;

export const Box = styled.div`
  @media ${breakpoints.mobile} {
    text-align: left;
  }

  @media ${breakpoints.laptop} {
    width: 50%;
    padding-left: 36px;
    box-sizing: border-box;
  }

  @media ${breakpoints.desktop} {
    padding-left: 50px;
  }
`;

export const Subtitle = styled.h4`
  @media ${breakpoints.mobile} {
    font-weight: 800;
    font-size: 20px;
    line-height: 34px;
    color: #222222;
    margin-bottom: 10px;
  }
`;

export const Text = styled.p`
  @media ${breakpoints.mobile} {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #222222;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
