import React, { useEffect } from 'react';
import { isBrowser } from '../utils/isBrowser';
import Layout from '../components/layout';
import { MainBlock1 } from '../components/mainBlock';
import SEO from '../components/seo';
import ThreeIconsBlock from '../components/threeIconsBlock';
import PictureAndText from '../components/pictureAndText';
import Reconstruction from '../components/reconstruction';
import MetaAnalitics from '../components/metaAnalitics';
import LastBlock from '../components/lastBlock';
import MainImage from '../assets/images/17.png';
import MetaSystemImage from '../assets/images/21.png';
import MetaSystemLogo from '../assets/icons/metasystem.svg';

import { BackgroundWrapper, InsuranceRenins } from '../styledComponents';

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isBrowser]);

  return (
    <Layout>
      <SEO
        title="Бесплатная установка системы слежения за автомобилем во время аренды"
        description="Онлайн наблюдение за перемещением автомобиля, фиксация ДТП, моментальные уведомления при угоне, эвакуации, снятия оборудования, аварии, контроль показателей уровня топлива, пробега, скорости и т.д."
      />
      <BackgroundWrapper background={`url(${MainImage}) center center`}>
        <MainBlock1
          title="Онлайн - контроль за автомобилем"
          text="Бесплатная установка телематического устройства для отслеживания показателей автомобиля во время аренды"
          button="Зарегистрировать авто"
          link="/app/car/register"
          guest
        >
          <InsuranceRenins>
            <MetaSystemLogo className="icon" />
          </InsuranceRenins>
        </MainBlock1>
      </BackgroundWrapper>
      <BackgroundWrapper>
        <ThreeIconsBlock />
      </BackgroundWrapper>
      <BackgroundWrapper>
        <PictureAndText />
      </BackgroundWrapper>
      <BackgroundWrapper background="#f1f6fd">
        <MetaAnalitics />
      </BackgroundWrapper>
      <BackgroundWrapper>
        <Reconstruction />
      </BackgroundWrapper>
      <BackgroundWrapper background={`url(${MetaSystemImage}) center`}>
        <LastBlock
          width="940px"
          title={
            <>
              Приедем к вам и установим бесплатно.
              <br />
              Без ежемесячных платежей
            </>
          }
          button="Зарегистрировать автомобиль"
          link="/app/car/register"
          bold
          guest
        />
      </BackgroundWrapper>
    </Layout>
  );
};
