import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FirstImage from '../../assets/images/26.png';
import SecondImage from '../../assets/images/27.png';
import ThirdImage from '../../assets/images/28.png';
import ForthImage from '../../assets/images/29.png';
import FirstArrowIcon from '../../assets/icons/arrow-4.svg';
import SecondArrowIcon from '../../assets/icons/arrow-5.svg';
import ThirdArrowIcon from '../../assets/icons/arrow-6.svg';
import ForthArrowIcon from '../../assets/icons/arrow-7.svg';
import DecorationIcon from '../../assets/icons/horizontal-points.svg';
import { breakpoints } from '../../constants/breakpoints';

import { Title } from '../../styledComponents';
import {
  Box,
  Colored,
  Container,
  Description,
  Text,
  Image,
  Subtitle,
  MetaAnaliticsWrapper,
} from './styles';

export default () => {
  const isLaptop = useMediaQuery(breakpoints.laptop);
  const isDesktop = useMediaQuery(breakpoints.desktop);

  return (
    <MetaAnaliticsWrapper>
      <Title className="title">
        Инновационное телематическое решение для управления автопарками
      </Title>
      <Subtitle>
        Гибкий инструмент для принятия решений на основе ключевых показателей эффективности парка
      </Subtitle>
      <Container>
        <Box>
          <Image image={FirstImage} />
          <Description large>
            <Colored>Контролируйте загрузку вашего парка</Colored>
            <Text width="350px">
              Отображение данных о текущем состоянии загрузки по каждому автомобилю
              за&nbsp;выбранный период.
              {isLaptop && <FirstArrowIcon className="icon -first" />}
            </Text>
          </Description>
        </Box>
        <Box inverted>
          {isDesktop && <DecorationIcon className="icon -horizontal" />}
          <Image inverted image={SecondImage} />
          <Description inverted>
            <Colored>Статистика по пробегу автомобилей</Colored>
            <Text width="350px">
              Получайте готовую экспертизу, основанную на телематических данных, и эффективно
              управляйте ценнобразованием.
              {isLaptop && <SecondArrowIcon className="icon -second" />}
            </Text>
          </Description>
        </Box>
        <Box>
          {isDesktop && <DecorationIcon className="icon -vertical" />}
          <Image image={ThirdImage} />
          <Description>
            <Colored>Статистика поездок{isDesktop && <br />} и оценка стиля вождения</Colored>
            <Text width="460px">
              {isLaptop && <ThirdArrowIcon className="icon -third" />}
              Готовый анализ поездок (время, пробег, скорость) с&nbsp;оценкой по стилю вождения (
              ускорения, торможения, повороты) и скорингом аварийности (отчет об аварии).
            </Text>
          </Description>
        </Box>
        <Box inverted>
          <Image inverted image={ForthImage} />
          <Description inverted>
            <Colored>История перемещений и контроль разрешенных зон эксплуатации</Colored>
            <Text width="380px">
              {isLaptop && <ForthArrowIcon className="icon -forth" />}
              Запросите маршрут перемещений вашего ТС. Создайте Геозоны и Объекты, задайте правила
              их контроля, настройте уведомления. Экспортируйте отчеты о движении автомобилей по
              геозонам и обектам.
            </Text>
          </Description>
        </Box>
      </Container>
    </MetaAnaliticsWrapper>
  );
};
