import styled from 'styled-components';
import { breakpoints } from '../../constants/breakpoints';

export const LastBlockWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1440px;
      box-sizing: border-box;
      padding: 270px 48px 80px 24px;
      text-align: center;
      mix-blend-mode: normal;
    }

    .link {
      text-align: left;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding-left: 75px;
      text-align: left;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      height: 550px;
    }
  }
`;

export const Title = styled.h2`
  @media ${breakpoints.mobile} {
    font-weight: ${(props) => (props.bold ? '800' : '600')};
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
    color: ${(props) => (props.dark ? '#222222' : '#ffffff')};
    text-shadow: ${(props) => (props.dark ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.288325)')};
    text-align: left;
  }

  @media ${breakpoints.tablet} {
    font-size: 28px;
    line-height: 35px;
    width: ${(props) => props.width || '560px'};
    max-width: 95%;
  }

  @media ${breakpoints.laptop} {
    font-size: ${(props) => (props.bold ? '32px' : '40px')};
    line-height: ${(props) => (props.bold ? '48px' : '64px')};
    width: ${(props) => props.width || '80%'};
  }

  @media ${breakpoints.desktop} {
    width: ${(props) => props.width || '915px'};
  }
`;

export const Text = styled.span`
  @media ${breakpoints.mobile} {
    font-weight: ${(props) => (props.bold ? '400' : '600')};
    font-size: 16px;
    line-height: 20px;
    margin: -8px 0 50px;
    color: ${(props) => (props.dark ? '#222222' : '#ffffff')};
    text-shadow: ${(props) => (props.dark ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.288325)')};
    text-align: left;
  }

  @media ${breakpoints.laptop} {
    font-size: 20px;
    line-height: 24px;
  }
`;
