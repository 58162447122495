import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MobileImage from '../../assets/images/23.png';
import DesktopImage from '../../assets/images/22.png';
import { breakpoints } from '../../constants/breakpoints';

import { Title } from '../../styledComponents';
import { Box, Container, PictureAndTextWrapper, Image, Subtitle, Text } from './styles';

export default () => {
  const isLaptop = useMediaQuery(breakpoints.laptop);

  return (
    <PictureAndTextWrapper>
      <Title className="title">Получайте уведомления моментально на ваш телефон</Title>
      <Container>
        <Image image={isLaptop ? DesktopImage : MobileImage} />
        <Box>
          <Subtitle>
            Телематическая платформа MetaTrak (Meta System S.p.A.) - это безопасность вашей машины и
            эффективный инструмент для принятия решений
          </Subtitle>
          <Text>
            Вы всегда сможете отследить свой автомобиль, контролировать статистику вождения и
            поездки, получать предупреждения о нарушениях правил использования.
          </Text>
          <Text>
            Получайте срочные уведомления в случаях, когда произошла авария (вы сможете увидеть
            отчет о ДТП прямо из приложения или отправить его на почту), если машину пытаются угнать
            или эвакуировать, при разрядке аккумулятора, а также, если оборудование пытаются
            демонтировать.
          </Text>
        </Box>
      </Container>
    </PictureAndTextWrapper>
  );
};
