import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MobileImage from '../../assets/images/25.png';
import DesktopImage from '../../assets/images/24.png';
import MobileIcon from '../../assets/icons/crash-image-mobile.svg';
import DesktopIcon from '../../assets/icons/crash-image.svg';
import { breakpoints } from '../../constants/breakpoints';

import { Title } from '../../styledComponents';
import { Colored, ReconstructionWrapper, Text, Image } from './styles';

export default () => {
  const isLaptop = useMediaQuery(breakpoints.laptop);

  return (
    <ReconstructionWrapper>
      <Title className="title">Реконструкция ДТП</Title>
      {isLaptop ? <DesktopIcon className="icon" /> : <MobileIcon className="icon" />}
      <Text>
        <Colored>Устройство Meta System </Colored>определяет аварию и производит ее реконструкцию с
        фиксацией повреждений автомобиля, незамедлительно формирует подробный отчёт с указанием
        силы, направления удара, времени и места происшествия, а также степени тяжести ДТП,
        возможных травм и пр.
      </Text>
      <Image image={isLaptop ? DesktopImage : MobileImage} />
    </ReconstructionWrapper>
  );
};
