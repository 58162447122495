import styled from 'styled-components';
import { breakpoints } from '../../constants/breakpoints';

export const ReconstructionWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1440px;
      padding: 80px 16px 55px;
      box-sizing: border-box;
      text-align: center;
    }

    .icon {
      margin: 30px 0 40px;
      width: 100vw;
      height: auto;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      padding: 80px 24px 55px;
    }

    .title {
      margin: 0;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      position: relative;
      padding: 100px 36px 90px;
    }

    .icon {
      order: 3;
      margin: 100px -36px 0;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: 140px 50px 120px;
    }

    .icon {
      width: 100vw;
      margin: 120px -50px 0;
    }
  }
`;

export const Text = styled.p`
  @media ${breakpoints.mobile} {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #222222;
    text-align: left;
  }

  @media ${breakpoints.laptop} {
    order: 1;
    width: 425px;
    margin-top: 60px;
    transform: translateX(-50%);
  }

  @media ${breakpoints.desktop} {
    width: 530px;
    margin-top: 80px;
    line-height: 44px;
  }
`;

export const Colored = styled.span`
  @media ${breakpoints.mobile} {
    font-weight: 600;
    color: #066bd6;
  }
`;

export const Image = styled.div`
  @media ${breakpoints.mobile} {
    width: 100%;
    height: 100vw;
    margin-top: 30px;
    background: ${(props) => `url(${props.image}) no-repeat`};
    background-size: contain;
    background-position: center;
  }

  @media ${breakpoints.laptop} {
    position: absolute;
    order: 2;
    width: 400px;
    height: 365px;
    margin-top: 0;
    top: 280px;
    left: 50%;
  }

  @media ${breakpoints.desktop} {
    width: 566px;
    height: 523px;
  }
`;
