import styled from 'styled-components';
import { breakpoints } from '../../constants/breakpoints';

export const MetaAnaliticsWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1440px;
      padding: 80px 16px 55px;
      box-sizing: border-box;
      text-align: center;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      padding: 80px 24px 55px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      position: relative;
      width: 100%;
      padding: 100px 36px 90px;
    }

    .icon {
      position: absolute;
    }

    .title {
      margin-bottom: 32px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: 140px 50px 120px;
    }

    .icon.-horizontal {
      top: -85px;
      right: 60px;
      rotate: 180deg;
    }

    .icon.-vertical {
      top: -110px;
      left: -5px;
      rotate: -90deg;
    }
  }
`;

export const Box = styled.div`
  @media ${breakpoints.mobile} {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ${breakpoints.laptop} {
    display: flex;
    width: 100%;
    flex-direction: ${(props) => (props.inverted ? 'row-reverse' : 'row')};
  }

  @media ${breakpoints.desktop} {
    position: relative;
    margin-bottom: 80px;
  }
`;

export const Colored = styled.h3`
  @media ${breakpoints.mobile} {
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #066bd6;
    margin-bottom: 8px;
  }

  @media ${breakpoints.laptop} {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 16px;
  }
`;

export const Container = styled.div`
  @media ${breakpoints.laptop} {
    width: 100%;
  }
`;

export const Description = styled.div`
  @media ${breakpoints.mobile} {
    & {
      text-align: left;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      width: 50%;
      padding: ${(props) => (props.inverted ? '0 8% 0 0' : '0 0 0 8%')};
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: ${(props) => (props.large ? '0 0 0 8%' : '0 8%')};
    }
  }
`;

export const Text = styled.p`
  @media ${breakpoints.mobile} {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #222222;
    margin-top: 4px;
  }

  @media ${breakpoints.laptop} {
    position: relative;
    width: ${(props) => props.width};

    .-first {
      top: 85px;
      left: -130px;
    }

    .-second {
      top: 90px;
      right: -195px;
    }

    .-third {
      top: 110px;
      left: -115px;
    }

    .-forth {
      top: 155px;
      right: -115px;
    }
  }
`;

export const Image = styled.div`
  @media ${breakpoints.mobile} {
    border-radius: 10px;
    height: 67vw;
    max-height: 300px;
    margin: 0 0 25px;
    background: ${(props) => `url(${props.image}) no-repeat`};
    background-size: contain;
    background-position: left center;
  }

  @media ${breakpoints.laptop} {
    width: 42%;
    background-size: 420px 287px;
    height: 287px;
    max-height: 287px;
    margin: ${(props) => (props.inverted ? '0 0 0 8%' : '0 8% 0 0')};
  }

  @media ${breakpoints.desktop} {
    filter: drop-shadow(-8px 3px 24px rgba(47, 46, 65, 0.37));
    /* margin: ${(props) => (props.inverted ? '0 16% 0 0' : '0 0 0 16%')}; */
    margin: 0 8%;
    width: 34%;
  }
`;

export const Subtitle = styled.h4`
  @media ${breakpoints.mobile} {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #222222;
    margin-bottom: 60px;
  }

  @media ${breakpoints.laptop} {
    font-size: 24px;
    line-height: 36px;
  }
`;
