import React from 'react';
import Button from '@material-ui/core/Button';
import { track } from '../../seo';

import { LastBlockWrapper, Title, Text } from './styles';

export default ({ guest, title, text, button, width, link, bold, dark, seo }) => (
  <LastBlockWrapper>
    <Title dark={dark} bold={bold} width={width}>
      {title}
    </Title>
    {text && (
      <Text bold={bold} dark={dark} text={text}>
        {text}
      </Text>
    )}
    <a href={link} title={button} className="link">
      <Button
        variant="contained"
        color={guest ? 'secondary' : 'primary'}
        onClick={() => {
          track(seo);
        }}
      >
        {button}
      </Button>
    </a>
  </LastBlockWrapper>
);
