import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LocationIcon from '../../assets/icons/location.svg';
import LocationImage from '../../assets/images/location.png';
import Lockcon from '../../assets/icons/lock.svg';
import LockImage from '../../assets/images/lock.png';
import SpeedometerIcon from '../../assets/icons/speedometer.svg';
import SpeedometerImage from '../../assets/images/speedometer.png';
import SeparatorIcon from '../../assets/icons/road-meta.svg';
import SeparatorMobileIcon from '../../assets/icons/road-meta-mobile.svg';

import { breakpoints } from '../../constants/breakpoints';

import { Title } from '../../styledComponents';
import { Container, Colored, ThreeIconsBlockWrapper, Box, Subtitle, Text } from './styles';

export default () => {
  const isLaptop = useMediaQuery(breakpoints.laptop);

  return (
    <ThreeIconsBlockWrapper>
      <Title className="title">
        Постоянная связь с автомобилем в <br />
        <Colored>мобильном приложении</Colored>
      </Title>
      <Container>
        <Box image={LocationImage}>
          <LocationIcon className="location" />
          <div>
            <Subtitle>Контролируйте местонахождение машины online</Subtitle>
            <Text>
              Наблюдайте за местонахождением машины на карте в режиме реального времени или
              просматривайте историю перемещения за выбранный период
            </Text>
          </div>
        </Box>
        <Box image={SpeedometerImage}>
          <SpeedometerIcon className="speedometer" />
          <div>
            <Subtitle>Отслеживайте статистику вождения и поездки</Subtitle>
            <Text>
              Контролируйте показатели пробега, расхода топлива, скорости, заряда АКБ автомобиля,
              продолжительности поездок, стиля вождения арендатора, аварий
            </Text>
          </div>
        </Box>
        <Box image={LockImage}>
          <Lockcon className="lock" />
          <div>
            <Subtitle>Ваш автомобиль всегда под охраной</Subtitle>
            <Text>
              Включайте режим охраны в вашем мобильном приложении и контролируйте перемещения вашего
              автомобиля
            </Text>
          </div>
        </Box>
      </Container>
      {isLaptop ? <SeparatorIcon className="icon" /> : <SeparatorMobileIcon className="icon" />}
    </ThreeIconsBlockWrapper>
  );
};
