import React from 'react';
import Button from '@material-ui/core/Button';
import { track } from '../../seo';

import { MainBlockWrapper1, MainBlockWrapper2, MainBlockWrapper3, Text, Title } from './styles';

export const MainBlock1 = ({ title, button, link, guest, text, children, color, seo }) => (
  <MainBlockWrapper1>
    <Title color={color}>{title}</Title>
    {text && <Text color={color}>{text}</Text>}
    <a href={link} title={button}>
      <Button
        className="btn"
        variant="contained"
        color={guest ? 'secondary' : 'primary'}
        onClick={() => {
          track(seo);
        }}
      >
        {button}
      </Button>
    </a>
    {children}
  </MainBlockWrapper1>
);

export const MainBlock2 = ({ title, button, link, guest, text, children, color, seo }) => (
  <MainBlockWrapper2>
    <Title color={color}>{title}</Title>
    {text && <Text color={color}>{text}</Text>}
    <a href={link} title={button}>
      <Button
        className="btn"
        variant="contained"
        color={guest ? 'secondary' : 'primary'}
        onClick={() => {
          track(seo);
        }}
      >
        {button}
      </Button>
    </a>
    {children}
  </MainBlockWrapper2>
);

export const MainBlock3 = ({ title, button, link, guest, text, children, color, seo }) => (
  <MainBlockWrapper3>
    <Title color={color}>{title}</Title>
    {text && <Text color={color}>{text}</Text>}
    <a href={link} title={button} className="link">
      <Button
        className="btn"
        variant="contained"
        color={guest ? 'secondary' : 'primary'}
        onClick={() => {
          track(seo);
        }}
      >
        {button}
      </Button>
    </a>
    {children}
  </MainBlockWrapper3>
);

export const MainBlock4 = ({ title, text, children, color }) => (
  <MainBlockWrapper3>
    <Title color={color}>{title}</Title>
    {text && <Text color={color}>{text}</Text>}
    {children}
  </MainBlockWrapper3>
);
