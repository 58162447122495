import styled from 'styled-components';
import { breakpoints } from '../../constants/breakpoints';

export const ThreeIconsBlockWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1440px;
      padding: 80px 16px 55px;
      box-sizing: border-box;
      text-align: center;
    }

    .title {
      margin-bottom: 35px;
    }

    .icon {
      width: 100vw;
      height: auto;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      padding: 80px 24px 55px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding: 100px 0 90px;
    }

    .title {
      margin-bottom: 70px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: 140px 0 120px;
    }
  }
`;

export const Container = styled.div`
  @media ${breakpoints.laptop} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const Colored = styled.b`
  @media ${breakpoints.mobile} {
    color: #066bd6;
  }
`;

export const Box = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      width: 100%;
      margin-bottom: 40px;
      padding-top: 20px;
      background: ${(props) => `url(${props.image}) no-repeat`};
      background-position-x: 16px;
      background-size: auto 125px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .location {
      flex: 0 0 50px;
      height: 45px;
      margin-right: 20px;
    }

    .lock {
      flex: 0 0 50px;
      height: 45px;
      margin-right: 20px;
    }

    .speedometer {
      flex: 0 0 50px;
      height: 45px;
      margin-right: 20px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      width: 40%;

      &:first-child {
        margin-right: 30px;
      }
    }
  }

  @media ${breakpoints.desktop} {
    & {
      width: 610px;

      &:first-child {
        margin-right: 45px;
      }
    }
  }
`;

export const Subtitle = styled.h3`
  @media ${breakpoints.mobile} {
    text-align: left;
    font-weight: 800;
    font-size: 20px;
    line-height: 32px;
    color: #222222;
    margin-bottom: 10px;
  }
`;

export const Text = styled.p`
  @media ${breakpoints.mobile} {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #222222;
  }
`;
